.answer img {
    height: 20rem !important;
}

.preview-blot{
    border: 0.1rem solid #f2f2f2;
    display: flex;
    flex-direction: row;
    border-radius: 0.8rem;
    padding: 1rem;
    justify-content: flex-start;
    gap: 1rem;
    font-weight: 700;
}
  
.preview-blot img{
    width: 10rem !important;
    height: 10rem !important;
    border-radius: 0.8rem;
    object-fit: cover;
}
  
.preview-blot span :last-child{
    font-weight: 400;
}

